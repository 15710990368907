import { useMemo } from "react";

import { useRecoilState } from "recoil";

import { CONFIGURATION_SECTION_PATH } from "../../../components/layout/adminMenu";
import logger from "../../../lib/logger-utils";
import { Global } from "../../../models/configuration/global";
import { imageOptimizationCrashedAtom } from "../atoms/imageOptimize";
import { usePublicRuntimeConfig } from "./usePublicRuntimeConfig";

import { useConfiguration } from ".";

/**
 * This hooks tell the code if he should use the image optimization or not
 */
export const useImageOptim = () => {
  const globalConfiguration = useConfiguration<Global, true>(
    CONFIGURATION_SECTION_PATH.GLOBAL,
    true,
  );

  const { config: publicRuntimeConfig, loading: publicRuntimeConfigLoading } =
    usePublicRuntimeConfig();

  const [crashed, setCrashed] = useRecoilState(imageOptimizationCrashedAtom);

  const shouldOptimize = useMemo(
    () =>
      Boolean(
        !(globalConfiguration?.imageOptimization === false) &&
          publicRuntimeConfig?.assets.paths &&
          !crashed,
      ),
    [crashed, globalConfiguration?.imageOptimization, publicRuntimeConfig?.assets.paths],
  );

  /**
   * Don't load any image until we know if we can
   */
  const loading = publicRuntimeConfigLoading || globalConfiguration?.isLoading;

  /**
   * If one of the image optimized crash, we should fallback to the non optimized ones
   */
  const crash = (src?: string) => {
    logger.debug("Image Optim have crashed, fallback to legacy", src);
    setCrashed(true);
  };

  const evaluateSrc = (src: string | undefined) => {
    if (!src || src === "about:blank") {
      logger.debug("Image Optim: evaluateSrc not passing -> reason src =", src);
      // avoid to crash for one image not passing
      return false;
    }

    return true;
  };

  return { loading, shouldOptimize, crash, evaluateSrc };
};
